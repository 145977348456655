import Application from 'components/comachicart/Application';
import Comparision from 'components/comachicart/Comparision';
import {PriceFAQ} from 'components/comachicart/FAQ';
import Layout from 'components/comachicart/Layout';
import Price from 'components/comachicart/Price';
import SEO from 'components/seo';
import React from 'react';

const PricePage = () => {
  return (
    <Layout>
      <SEO title='comachicart' path='comachicart/price' />
      <Price visibleVoice={true} />
      <Comparision />
      <Application />
      <PriceFAQ />
    </Layout>
  );
};

export default PricePage;
